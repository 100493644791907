<template>
	<div class="d-flex flex-column flex-root">
		<div class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white" :class="{'login-signin-on': this.state == 'signin','login-signup-on': this.state == 'signup','login-forgot-on': this.state == 'forgot'}" id="kt_login">
			<div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #eee;">
				<div class="d-flex flex-column-auto flex-column pt-lg-40 pt-40">
					<a href="#" class="text-center mb-10 pt-40">
						<img :src="ImgLogo" class="max-h-70px" alt="" />
					</a>
				</div>
			</div>
			<div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
				<div class="d-flex flex-column-fluid flex-center">
					<div class="login-form login-signin">
						<form class="form" novalidate="novalidate" id="kt_login_signin_form" ref="kt_login_signin_form" @submit.stop.prevent="onSubmitLogin()">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" >
									{{ $tc("AUTH.TEXT.CONNECTED") }}
								</h3>
								<!--<span class="text-muted font-weight-bold font-size-h4">
									New Here?
									<a id="kt_login_signup" class="text-primary font-weight-bolder" @click="showForm('signup')">Create an Account</a>
								</span>-->
							</div>
							<div class="form-group">
								<label class="font-size-h6 font-weight-bolder text-dark">{{$tc("AUTH.INPUT.EMAIL")}}</label>
								<div id="example-input-group-1" label="" label-for="example-input-1">
									<input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email"  ref="email"/>
								</div>
							</div>
							<div class="form-group">
								<div class="d-flex justify-content-between mt-n5">
									<label class="font-size-h6 font-weight-bolder text-dark pt-5">{{$tc("AUTH.INPUT.PASSWORD")}}</label>
									<a class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot" @click="showForm('forgot')" >{{$tc("AUTH.BUTTON.FORGOT")}}</a>
								</div>
								<div id="example-input-group-2" label="" label-for="example-input-2">
									<input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" name="password" ref="password" autocomplete="off"/>
								</div>
							</div>
							<div class="pb-lg-0 pb-5">
								<button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"  >
									{{$tc("AUTH.BUTTON.SIGN_IN")}}
								</button>
								<!--<button type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg">
									<span class="svg-icon svg-icon-md"><inline-svg src="media/svg/social-icons/google.svg"/> </span>
									Sign in with Google
								</button>-->
							</div>
						</form>
					</div>
					<div class="login-form login-forgot">
						<form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Forgotten Password ?
								</h3>
								<p class="text-muted font-weight-bold font-size-h4">
									Enter your email to reset your password
								</p>
							</div>
							<div class="form-group">
								<input
								class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
								type="email"
								placeholder="Email"
								name="email"
								autocomplete="off"
								/>
							</div>
							<div class="form-group d-flex flex-wrap pb-lg-0">
								<button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">
									Submit
								</button>
								<button type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click="showForm('signin')">
									Cancel
								</button>
							</div>
						</form>
					</div>
				</div>
				<div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
					<a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
					<a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
					<a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
				</div>
			</div>
		</div>
	</div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN,LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
//import axios from "axios";

export default {
name: "login-1",
data() {
	return {
		state: "signin",
		/*form: {
			email: "",
			password: ""
		}*/
	};
},
computed: {
	...mapState({
		errors: state => state.auth.errors
	}),
	...mapGetters(["currentUser"]),
	ImgLogo() {
		return (
			process.env.BASE_URL + "media/tlmtk/sayartechpro-logo.png"
		);
	}
},
mounted() {
	const signin_form = KTUtil.getById("kt_login_signin_form");
	//const signup_form = KTUtil.getById("kt_login_signup_form");
	//const forgot_form = KTUtil.getById("kt_login_forgot_form");
	localStorage.setItem("language","fr")

	this.fv = formValidation(signin_form, {
		fields: {
			email: {
				validators: {
					notEmpty: {
						message: this._i18n.messages[localStorage.getItem("language") ? localStorage.getItem("language") : "fr"].AUTH.VALIDATION.EMAIL
					}
				}
			},
			password: {
				validators: {
					notEmpty: {
						message: this._i18n.messages[localStorage.getItem("language") ? localStorage.getItem("language") : "fr"].AUTH.VALIDATION.PASSWORD
					}
				}
			}
		},
		plugins: {
			trigger: new Trigger(),
			submitButton: new SubmitButton(),
			bootstrap: new Bootstrap()
		}
	});

},
methods: {
	showForm(form) {
		//console.log(messages);
		this.state = form;
		var form_name = "kt_login_" + form + "_form";
		KTUtil.animateClass(
			KTUtil.getById(form_name),
			"animate__animated animate__backInUp"
		);
	},
	LoginAction(result) {
		this.$refs["kt_login_signin_submit"].remove(
					"spinner",
					"spinner-light",
					"spinner-right"
				);
		if(result == "false" || result == false || result == "error"){
			Swal.fire({
				title: this._i18n.messages[localStorage.getItem("language")].AUTH.TEXT.ERROR_LOGIN_TITLE,
				text: this._i18n.messages[localStorage.getItem("language")].AUTH.TEXTERROR_LOGIN_TEXT,
				icon: "error",
				confirmButtonClass: "btn btn-secondary",
				heightAuto: false
			});
		}else{
			this.$router.push({ name: "startapp" })
		}
	},

	onSubmitLogin() {
		
		this.$refs["kt_login_signin_submit"].classList.add("spinner", "spinner-light", "spinner-right");
		//console.log(this.fv.validateLogin());
		this.fv.on("core.form.valid", () => {
			//alert();
			var email = this.$refs.email.value;
			var password = this.$refs.password.value;
			var newsession = true;
			this.$store.dispatch(LOGOUT);

			const submitButton = this.$refs["kt_login_signin_submit"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");
			this.$store.dispatch(LOGIN, { email, password, newsession })
				.then((rep) => 
					this.LoginAction(rep.result)
					//console.log(rep.result)
					//this.$router.push({ name: "dashboard" })
				).catch(() => {});
				submitButton.classList.remove(
					"spinner",
					"spinner-light",
					"spinner-right"
				);
			//setTimeout(() => {
				
			//}, 500);
		});

		this.fv.on("core.form.invalid", () => {
			Swal.fire({
				title: "",
				text: "Please, provide correct data!",
				icon: "error",
				confirmButtonClass: "btn btn-secondary",
				heightAuto: false
			});
		});
	},

	onSubmitRegister() {
		this.fv1.validate();

		this.fv1.on("core.form.valid", () => {
			const email = this.$refs.remail.value;
			const password = this.$refs.rpassword.value;

			this.$store.dispatch(LOGOUT);

			const submitButton = this.$refs["kt_login_signup_submit"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			setTimeout(() => {
				this.$store.dispatch(REGISTER, {email: email,password: password}).then(() => this.$router.push({ name: "startapp" }));
				submitButton.classList.remove(
					"spinner",
					"spinner-light",
					"spinner-right"
				);
			}, 2000);
		});

		this.fv1.on("core.form.invalid", () => {
			Swal.fire({
				title: "",
				text: "Please, provide correct data!",
				icon: "error",
				confirmButtonClass: "btn btn-secondary",
				heightAuto: false
			});
		});
	}
}
};
</script>
